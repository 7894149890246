import { USER_ROLES } from './../enums/roles';
import { Injectable } from "@angular/core"
import { BehaviorSubject, Subject } from "rxjs"

const ROLES_CAN_ADD = {
	ACCOUNTANT: [],
	VIEWER: [],
	COLLABORATOR: [USER_ROLES.COLLABORATOR, USER_ROLES.VIEWER, USER_ROLES.ACCOUNTANT],
	ADMIN: [USER_ROLES.ADMIN, USER_ROLES.COLLABORATOR, USER_ROLES.VIEWER, USER_ROLES.ACCOUNTANT],
	OWNER: [USER_ROLES.ADMIN, USER_ROLES.COLLABORATOR, USER_ROLES.VIEWER, USER_ROLES.ACCOUNTANT]
}

const ROLES_CAN_DELETE = {
	ACCOUNTANT: [],
	VIEWER: [],
	COLLABORATOR: [USER_ROLES.VIEWER],
	ADMIN: [USER_ROLES.ADMIN, USER_ROLES.COLLABORATOR, USER_ROLES.VIEWER, USER_ROLES.ACCOUNTANT],
	OWNER: [USER_ROLES.ADMIN, USER_ROLES.COLLABORATOR, USER_ROLES.VIEWER, USER_ROLES.ACCOUNTANT]
}

const ROLES_CAN_CHANGE = {
	ACCOUNTANT: [],
	VIEWER: [],
	COLLABORATOR: [USER_ROLES.VIEWER],
	ADMIN: [USER_ROLES.ADMIN, USER_ROLES.COLLABORATOR, USER_ROLES.VIEWER, USER_ROLES.ACCOUNTANT],
	OWNER: [USER_ROLES.ADMIN, USER_ROLES.COLLABORATOR, USER_ROLES.VIEWER, USER_ROLES.ACCOUNTANT]
}

@Injectable({
	providedIn: "root"
})
export class OrganizationRoleService {
	private _role!: USER_ROLES;
	private _role$: BehaviorSubject<USER_ROLES> = new BehaviorSubject('' as USER_ROLES)

	constructor() {}

	/**
	 * Gets the current user role on the workspace
	 * @returns Subject<string>
	 */
	getUserRole(): Subject<USER_ROLES> {
		return this._role$
	}

	/**
	 * Updates the user role based on the current workspace
	 */
	updateUserRole(role: USER_ROLES): void {
    this._role = role
    this._role$.next(role)
	}

	/**
	 * Checks if user can edit an event
	 * @returns boolean
	 */
	canEditEvent(): boolean {
		return ![USER_ROLES.VIEWER, USER_ROLES.ACCOUNTANT].includes(this._role)
	}

	/**
	 * Checks if user can create an event
	 * @returns boolean
	 */
	canCreateEvent(): boolean {
		return ![USER_ROLES.VIEWER, USER_ROLES.ACCOUNTANT].includes(this._role)
	}

	/**
	 * Checks if user can add a user to a workspace
	 * @returns boolean
	 */
	canAddUsers(role: string = this._role): boolean {
		return ["owner", USER_ROLES.ADMIN, USER_ROLES.COLLABORATOR].includes(role)
	}

	/**
	 * List of roles that the user can assign to other users
	 * @param role Current user role
	 * @returns boolean
	 */
	rolesListToAdd(role: USER_ROLES = this._role): string[] {
		return ROLES_CAN_ADD[role]
	}

	/**
	 * List of roles that the user can assign to other users
	 * @param role Current user role
	 * @returns boolean
	 */
	rolesListToDelete(role: USER_ROLES = this._role): string[] {
		return ROLES_CAN_DELETE[role]
	}

	/**
	 * List of roles that the user can change for other users
	 * @param role Current user role
	 * @returns boolean
	 */
	rolesListToChange(role: USER_ROLES = this._role): string[] {
		return ROLES_CAN_CHANGE[role]
	}

	/**
	 * Checks if user can view the database
	 * @returns boolean
	 */
	canViewDatabase(): boolean {
		return ![USER_ROLES.ACCOUNTANT].includes(this._role)
	}

	/**
	 * Checks if user can edit the database
	 * @returns boolean
	 */
	canEditDatabase(): boolean {
		return ![USER_ROLES.ACCOUNTANT].includes(this._role)
	}

	/**
	 * Checks if user can export an event's database
	 * @returns boolean
	 */
	canExportDatabase(): boolean {
		return ["owner", USER_ROLES.ADMIN].includes(this._role)
	}

	/**
	 * Checks if user can export master database
	 * @returns boolean
	 */
	canExportMasterDatabase(): boolean {
		return ["owner", USER_ROLES.ADMIN].includes(this._role)
	}

	/**
	 * Checks if user can add creditcard
	 * @returns boolean
	 */
	canAddCreditcard(): boolean {
		return ["owner", USER_ROLES.ADMIN, USER_ROLES.ACCOUNTANT, USER_ROLES.COLLABORATOR].includes(
			this._role
		)
	}

	/**
	 * Checks if user can remove creditcard
	 * @returns boolean
	 */
	canRemoveCreditcard(): boolean {
		return ["owner", USER_ROLES.ADMIN, USER_ROLES.ACCOUNTANT].includes(this._role)
	}

	/**
	 * Checks if user can edit subscription
	 * @returns boolean
	 */
	canEditSubscription(): boolean {
		return ["owner", USER_ROLES.ADMIN, USER_ROLES.ACCOUNTANT].includes(this._role)
	}
}
