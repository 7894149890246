import {
  OrganizationRequest,
  OrganizationResponse,
} from '@plan/organization/types/organization.type';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, of, take } from 'rxjs';
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpResponse,
} from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Roles, User, UserInfo } from '@shared/types/user.type';
import { AppService } from '../../../shared/services/app.service';
import { Member } from '@plan/organization/types/member.type';
import { LegalInfo, PatchLegalInfo } from '../types/legal-info.type';
import { Response } from '@shared/types/generic-response.type';
import {
  KeyContact,
  PublicInfo,
  PublicInfoPatchData,
  SocialMediaPresets,
} from '../types/public-info.type';
import { OrganizationRoleService } from '@shared/services/organization-role.service';
import { EventsCount, SharedEventResponse } from '@plan/events/types/event';
import { Router } from '@angular/router';
import { EVENT_STATUS } from '@plan/events/enums/event-status';

export type PublicInfoResponse = Response<{
  organization: PublicInfo;
  key_contacts: KeyContact[];
  social_media: SocialMediaPresets[];
}>;

export type MeasurmentAttribute = 'WEIGHT' | 'LENGTH';

type MemberResponse = { message: string; data: Member & { user_id: number } };
@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  private _currentOrganization: OrganizationResponse | null = null;
  public organizations$: BehaviorSubject<OrganizationResponse[]> =
    new BehaviorSubject([] as OrganizationResponse[]);
  currentOrganizationChange$: BehaviorSubject<OrganizationResponse | null> =
    new BehaviorSubject(null as OrganizationResponse | null);
  public changeUpdateBarStatus$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(
    private _Http: HttpClient,
    private _AppService: AppService,
    private _OrganizationRoleService: OrganizationRoleService,
    private _Router: Router
  ) {}

  /**
   * Method used to get current organization
   * @returns current organization
   */
  get currentOrganization(): OrganizationResponse | null {
    if (!this._currentOrganization)
      this._currentOrganization = JSON.parse(
        (localStorage.getItem('currentOrganization') as string) ||
          (sessionStorage.getItem('currentOrganization') as string)
      );
    this._OrganizationRoleService
      .getUserRole()
      .pipe(take(1))
      .subscribe((role) => {
        if (!role && this._currentOrganization)
          this._OrganizationRoleService.updateUserRole(
            this._currentOrganization.current_user_role
          );
      });
    return this._currentOrganization;
  }

  /**
   * Method used to set current organization
   * @param organization organization data
   */
  set currentOrganization(organization: OrganizationResponse | null) {
    const userData = JSON.parse(localStorage.getItem('userData') as string);
    if (userData)
      localStorage.setItem('currentOrganization', JSON.stringify(organization));
    else
      sessionStorage.setItem(
        'currentOrganization',
        JSON.stringify(organization)
      );
    this._currentOrganization = organization;
    this.currentOrganizationChange$.next(organization);
    this._OrganizationRoleService.updateUserRole(
      organization!.current_user_role
    );
    this._AppService.updateNavbar({
      organizationName: organization?.name,
      organizationLogo: organization?.logo,
      showOrganizationSection: true,
    });
  }

  public checkIfOrganizationExist(): void {
    if (this._currentOrganization) return;

    const organization =
      JSON.parse(localStorage.getItem('currentOrganization') as string) ||
      JSON.parse(sessionStorage.getItem('currentOrganization') as string);

    if (organization) {
      const isOrganizationExist = this.organizations$.value.find(
        (org) => org.id === organization.id
      );

      if (!isOrganizationExist) {
        localStorage.removeItem('currentOrganization');
        sessionStorage.removeItem('currentOrganization');
        this._currentOrganization = null;
        this._Router.navigate(['/planning/create-organization']);
      } else this.currentOrganization = organization;
      return;
    }

    if (this.organizations$.value.length)
      this.currentOrganization = this.organizations$.value[0];
  }

  /**
   * Invite Team members to the created organization
   * @param payload IMember[]
   * @returns Observable<any>
   */
  public inviteTeamMember(
    payload: Member,
    organizationId: number
  ): Observable<MemberResponse> {
    return this._Http.post<MemberResponse>(
      `${environment.planApi}${organizationId}/users/invite-team`,
      payload
    );
  }

  /**
   * Method used to fetch organizations
   * @returns observable of type organizations data
   */
  getOrganizations(): Observable<OrganizationResponse[]> {
    return this._Http
      .get<{
        data: { organizations: OrganizationResponse[] };
        message: string;
      }>(`${environment.rootApi}organizations`)
      .pipe(
        map(
          (res: {
            data: { organizations: OrganizationResponse[] };
            message: string;
          }) => {
            this.organizations$.next(res.data.organizations);
            this.checkIfOrganizationExist();
            return res.data.organizations;
          }
        )
      );
  }

  /**
   * Method used to create organization
   * @param data organization data
   * @returns observable of type organization data
   */
  createOrganization(
    data: OrganizationRequest
  ): Observable<{ organization: OrganizationResponse }> {
    return this._Http
      .post<{ data: { organization: OrganizationResponse } }>(
        `${environment.rootApi}organizations`,
        data
      )
      .pipe(
        map((res: { data: { organization: OrganizationResponse } }) => {
          this.currentOrganization = res.data.organization;
          return res.data;
        })
      );
  }

  /**
   * Method used to check if the slug is already taken.
   * @param slug organization slug
   * @returns observable of type { slugTaken: boolean } | null
   */
  organizationExist(slug: string): Observable<{ slugTaken: boolean } | null> {
    return this._Http
      .post<{ workspace_exist: boolean }>(
        `${environment.planApi}organization-exist`,
        {
          acronym: slug,
        }
      )
      .pipe(
        map((res: any) =>
          res.data.organization_exist ? { slugTaken: true } : null
        )
      );
  }

  /**
   * @method fetchPublicInfo
   * @description fetches public info of an organization by id
   * @param id number
   * @returns Observable<PublicInfoResponse>
   */
  public fetchPublicInfo(id: number): Observable<PublicInfoResponse> {
    return this._Http.get<PublicInfoResponse>(
      `${environment.planApi}${id}/public-info`
    );
  }

  /**
   * @method updatePublicInfo
   * @description updates public info of an organization by id
   * @param formValue PublicInfoPatchData
   * @returns Observable<PublicInfoResponse>
   */
  public updatePublicInfo(
    formValue: PublicInfoPatchData
  ): Observable<PublicInfoResponse> {
    return this._Http.patch<PublicInfoResponse>(
      `${environment.planApi}${this.currentOrganization!.id}/public-info`,
      formValue
    );
  }

  getLegalInfo(
    orgId: number
  ): Observable<{ data: { organization: LegalInfo } }> {
    return this._Http.get<{ data: { organization: LegalInfo } }>(
      `${environment.planApi}${orgId}/legal-info`
    );
  }

  /**
   * @description updates Legal info data
   * @param orgId accepts number
   * @param data accepts {licenses_permits: MediaImage[]}
   * @returns Observable<Response<LegalInfo>>
   */
  updateLegalInfo(
    orgId: number,
    data: Omit<PatchLegalInfo, 'licenses_permits'>
  ): Observable<Response<LegalInfo>> {
    return this._Http.patch<Response<LegalInfo>>(
      `${environment.planApi}${orgId}/legal-info`,
      data
    );
  }

  /**
   * @description updates legal info licenses files
   * @param orgId accepts number
   * @param data accepts {licenses_permits: MediaImage[]}
   * @returns Observable<number | HttpEvent<Response<{organization :LegalInfo}>> | HttpResponse<Response<{organization :LegalInfo}>>>
   */
  updateLegalInfoFiles(
    orgId: number,
    data: Pick<PatchLegalInfo, 'licenses_permits'>
  ): Observable<number | HttpEvent<Response<{ organization: LegalInfo }>>> {
    const formData = this._AppService.objectToFormData(data);
    formData.append('_method', 'PATCH');

    return this._Http
      .post<Response<{ organization: LegalInfo }>>(
        `${environment.planApi}${orgId}/legal-info`,
        formData,
        {
          reportProgress: true,
          observe: 'events',
        }
      )
      .pipe(
        map((event) => {
          if (event.type === HttpEventType.UploadProgress) {
            const progress = Math.round(
              (100 * event.loaded) / (event.total || 1)
            );
            return progress;
          } else return event;
        })
      );
  }

  getUsers(orgId: number) {
    return this._Http.get<{ data: { users: User[] } }>(
      `${environment.planApi}${orgId}/users`
    );
  }

  getRoles() {
    return this._Http.get<{ data: { users: Roles[] } }>(
      `${environment.planApi}roles`
    );
  }

  getStatuses() {
    return this._Http.get(`${environment.testApi}users/statuses`);
  }

  updateUserRole(orgId: number, userId: number, data: UserInfo) {
    return this._Http.patch(
      `${environment.planApi}${orgId}/users/${userId}/update-role`,
      data
    );
  }

  updateStatus(orgId: number, userId: number, data: UserInfo) {
    return this._Http.patch(
      `${environment.planApi}${orgId}/users/${userId}/update-status`,
      data
    );
  }

  deleteUser(orgId: number, userId: number): Observable<{ message: string }> {
    return this._Http.post<{ message: string }>(
      `${environment.planApi}${orgId}/users/${userId}/remove`,
      null
    );
  }

  resendInvitation(
    orgId: number,
    userId: number
  ): Observable<{ message: string }> {
    return this._Http.post<{ message: string }>(
      `${environment.planApi}${orgId}/users/${userId}/reinvite`,
      null
    );
  }

  fetchDashboardData() {
    return this._Http.get(
      `${environment.planApi}${this.currentOrganization?.id}/statistics`
    );
  }

  getEventsData(
    page: number,
    per_page: number,
    searchValue: string,
    status: EVENT_STATUS
  ): Observable<SharedEventResponse> {
    const queryParams = `?page=${page}&per_page=${per_page}${
      searchValue ? `&search=${searchValue}` : ''
    }${status ? `&status=${status === EVENT_STATUS.ALL ? '' : status}` : ''}`;

    return this._Http.get<SharedEventResponse>(
      `${environment.planApi}${this.currentOrganization?.id}/events${queryParams}`
    );
  }

  getEventsCount(
    searchValue: string
  ): Observable<Response<{ counts: EventsCount }>> {
    const queryParams = searchValue ? `?search=${searchValue}` : '';
    return this._Http.get<Response<{ counts: EventsCount }>>(
      `${environment.planApi}${this.currentOrganization?.id}/events/count${queryParams}`
    );
  }

  getUnitOfMeasurement(
    attribute: MeasurmentAttribute
  ): 'lbs' | 'kg' | 'in' | 'cm' | undefined {
    switch (attribute) {
      case 'WEIGHT':
        return this.currentOrganization?.measurement_system === 'IMPERIAL'
          ? 'lbs'
          : 'kg';
      case 'LENGTH':
        return this.currentOrganization?.measurement_system === 'IMPERIAL'
          ? 'in'
          : 'cm';
      default:
        return undefined;
    }
  }
}
